
.loading {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bolder;
}
.selected-item {
    background-color: #2e46cc;
    color: white;
    padding: 2px;
    border-radius: 4px;
    box-shadow: 2px 2px 4px 4px #2e46cc;
}
.unselected-item {
    opacity: 1;
}
.card {
    font-size: 6px;
}
.confirm-box  {
    position: fixed !important;
    top: 50%!important;
    left: 50%!important;
}
.confirm-box .confirm-box__content {
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%,-50%);
    background: #21212177;
    color: #fefefe;
    text-align: center;
    padding: 30px;
}
.confirm-box__actions {
    display: flex;
    padding: 20px;
    align-items: center;
    justify-content: center !important;
}
.confirm-box__actions button {
    background: none;
    border: 1px solid white;
    color: white;
    padding: 10px;
    border-radius: 20px;
    width: 100px;
}
@keyframes borderDance {
    0% {
        background-position: 0 0, 50px 100%, 0 50px, 100% 0;
    }
    100% {
        background-position: 50px 0, 0 100%, 0 0, 100% 50px;
    }
}
:root{
    .react-calendar-timeline .rct-header-root {
        background: black;
        border-bottom: 1px solid #bbb;
        position: fixed;
        margin-top: -60px;
        z-index: 109;
    }

    .react-calendar-timeline .rct-calendar-header {
        border: 1px solid #bbb;
        /* font-size: small; */
        background-color: black;
        /* font-weight: bold; */
    }

    .react-calendar-timeline .rct-dateHeader {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        border-bottom: 1px solid #bbb;
        cursor: pointer;
        font-size: 5px;
        background-color: #f0f0f0;
        border-left: 2px solid #bbb;
    }

    .react-calendar-timeline .rct-dateHeader-primary {
        background-color: initial;
        border-left: 1px solid #bbb;
        border-right: 1px solid #bbb;
        color: #fff;
        font-size: 9px;
        font-weight: bold;
    }

    .react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd {
        background-color: #fff;
        font-size: 9pt;
    }

    .react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-even {
        background-color: #fff;
        font-size: 9pt;
    }

    .react-calendar-timeline .rct-dateHeader-primary{
        color: white;
    }

    .react-calendar-timeline .rct-dateHeader{
        font-size: 10pt;
        font-weight: bold;
    }

    .react-calendar-timeline .rct-header-root{
        background-color: white;
        text-align: center;
    }
    .react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-even{
        padding-left: 10px;
    }
    .react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd{
        padding-left: 10px;
    }
    .react-calendar-timeline .rct-horizontal-lines .rct-hl-even, .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
        border-bottom: 1px solid #333333;
        box-sizing: border-box;
        z-index: 40;
    }
    .rct-item {
        font-size: 9px;
        border-radius: 0px;
        color:white;
        /* background-color: black; */
        padding: '3px 0';
        border: 0px;
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
    }
    .panel {
        background-color: white;
        padding: 12px;
    }
    .panel-content{
        margin-top: -10px;
    }

    #legend {
        /* position: absolute; */
        z-index: 89;
        bottom: 10px;
        left: 10px;
        font-size: 9pt;
        margin: 25px;
        width: fit-content;
    }
    #legend ul {
        list-style: none;
        padding: 0;
        margin: 0;
        background-color: 'aliceblue';
    }
    .strikethrough {
        text-decoration: line-through;
    }
    .light {
        #legend {
            color: #212121;
        }
        #legend tr {
            cursor: pointer;
        }
        .react-calendar-timeline .rct-outer {
            display: block;
            overflow: hidden;
            white-space: nowrap;
            background-color: white;
            color: black;
            margin-top: 60px;
        }
        .react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-even {
            background-color: white;
            color: black;
        }
        .react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd {
            background-color: rgba(0, 0, 0, 0.05);
            color: black;
        }
        .react-calendar-timeline .rct-header-root {
            background-color: white;
            text-align: center;
            color: black;
        }
        .animated-border {
            width: 200px;
            height: 200px;
            border: 2px dashed black;
            animation: dash 2s linear infinite;
        }
    }
    .dark {
        #legend {
            color: #FFFFFF;
        }
        #legend tr {
            cursor: pointer;
        }
        .react-calendar-timeline .rct-outer {
            display: block;
            overflow: hidden;
            white-space: nowrap;
            background-color: black;
            color: white;
            margin-top: 60px;
        }
        .react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-even {
            background-color: #212121;
            color: white;
        }
        .react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd {
            background-color: #fefefe11;
            color: white;
        }
        .react-calendar-timeline .rct-header-root {
            background-color: #333;
            text-align: center;
            color: white;
        }
        .react-calendar-timeline .rct-dateHeader {
            background-color: #000;
        }
        .react-calendar-timeline .rct-horizontal-lines .rct-hl-even {
            background: #212121;
        }
        .animated-border {
            width: 200px;
            height: 200px;
            border: 2px dashed white;
            animation: dash 2s linear infinite;
        }
        .rct-dateHeader {
            color: #FFFFFF
        }
    }

    #changeLang {
        font-size: 6pt;
    }
    #button {
        font-size: 6pt;
    }
    #theme {
        position: absolute;
        bottom: 10px;
        right: 10px;
        font-size: 9pt;
        margin-top: 10px;
    }
}
