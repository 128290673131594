.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.light-theme {
  --text-primary: var(--text-primary-light);
  --text-secondary: var(--text-secondary-light);
  --bg-primary: var(--bg-primary-light);
  --bg-secondary: var(--bg-secondary-light);
  --bg-navbar: var(--bg-navbar-light);
}

.dark-theme {
  --text-primary: var(--text-primary-dark);
  --text-secondary: var(--text-secondary-dark);
  --bg-primary: var(--bg-primary-dark);
  --bg-secondary: var(--bg-secondary-dark);
  --bg-navbar: var(--bg-navbar-dark);
}